<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Kurzy měn - seznam</strong>
      </div>
      <action-tools :router-options="[]" :handle-refresh="handleRefresh"></action-tools>
    </div>
    <div class="card-body">
      <div class="row mb-3">
        <div class="text-right col-md-12">
          <a-input-search
            placeholder="Hledat..."
            style="width: 200px"
            @search="handleSearch"
          />
        </div>
      </div>
      <a-table
        tableLayout="auto"
        :rowKey="record => record.id"
        :columns="columns"
        :loading="loading"
        :dataSource="currencies"
        class="utils__scrollTable"
        :scroll="{ x: '100%' }"
      >
        <span
          slot="id"
          slot-scope="value"
        >#<span v-if="searchText">
            <template
              v-for="(fragment, i) in value.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value}}</template>
        </span>
        <template slot="customRender" slot-scope="text">
          <a-alert v-if="text === undefined" message="není nastaveno" type="warning" showIcon />
          <span v-else-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{text}}</template>
        </template>
        <span
          slot="dates"
          slot-scope="text"
        >
          <span v-if="searchText">
            <template v-for="(fragment, i) in toLocaleDate(text).toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))" >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{toLocaleDate(text)}}</template>
        </span>
        <template slot="footer">
          {{ currencies.length }} položek
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'

export default {
  components: { ActionTools },
  data: function () {
    return {
      searchText: '',
      loading: false,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => a.id - b.id,
          scopedSlots: {
            customRender: 'id',
          },
        },
        {
          title: 'Název',
          dataIndex: 'name',
          sorter: (a, b) => {
            return a.name.localeCompare(b.name)
          },
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Označení',
          dataIndex: 'code',
          sorter: (a, b) => {
            return a.code.localeCompare(b.code)
          },
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Symbol',
          dataIndex: 'symbol',
          sorter: (a, b) => {
            return a.symbol.localeCompare(b.symbol)
          },
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Kurz',
          dataIndex: 'rate',
          sorter: (a, b) => {
            return a.rate - b.rate
          },
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Množství',
          dataIndex: 'amount',
          sorter: (a, b) => {
            return a.amount - b.amount
          },
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Poslední změna',
          dataIndex: 'updated_at',
          sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
          scopedSlots: {
            customRender: 'dates',
          },
        },
      ],
    }
  },
  computed: {
    currencies: function () {
      return this.$store.getters['currency/allItems'](this.searchText)
    },
  },
  methods: {
    toLocaleDate: function (text) {
      return this.$options.filters.formatDate(text)
    },
    handleRefresh() {
      this.loading = true
      this.$store.dispatch('currency/getList')
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    handleSearch(searchText) {
      this.searchText = searchText
    },
  },
  created() {
    if (this.currencies.length === 0) {
      this.handleRefresh()
    }
  },
}
</script>

<style lang="scss" module scoped>
  @import "./style.module.scss";
</style>
